import Navbar from './Navbar';
import Footer from './Footer';
import ssyImg from './assets/sheSaidYes.jpg';

function App() {
  return (
    <div className='min-h-screen w-screen bg-white grid grid-cols-1 selection:bg-genoa-200 py-20'>
      <Navbar />
      <div className="prose lg:w-3/5 px-4 lg:px-0 justify-self-center align-baseline py-6">
        <div className='text-5xl lg:text-6xl 2xl:text-8xl text-center font-body text-alabaster-800'>
          Jessie & Tyler
        </div>
        <div className='text-2xl lg:text-3xl 2xl:text-5xl text-center font-body text-alabaster-800 pt-6'>
          April 27th, 2024 | Evergreen, CO
        </div>

        <img src={ssyImg} alt="she said yes" className="rounded p-2 bg-gray-800" />

        {/* <div className='w-full flex justify-center pt-6'>
          <a href='/rsvp'>
            <button class="bg-alabaster-800 text-white rounded py-2 px-3 justify-center">
              RSVP
            </button>
          </a>
        </div> */}

        <div className="font-serif text-gray-600 text-sm">

          <div className="grid grid-cols-2">
            <div>
              <h4 className="text-gray-800 text-lg">
                April 26th 7:00PM – 9:00PM
              </h4>
            </div>
            <div>
              <h4 className="text-left uppercase">
                Welcome Drinks
              </h4>
              <p className="pb-2">
                Revival Brews
              </p>
              <p className="pb-2">
                27845 Highway 74, Evergreen, CO 80439
              </p>
              <p>
                Kickoff the wedding weekend with us if you are in town!
              </p>
            </div>
            < hr className='col-span-2' />

            <div>
              <h4 className="text-gray-800 text-lg">
                April 27th 5:00PM – 5:30PM
              </h4>
            </div>
            <div>
              <h4 className="text-left uppercase">
                Ceremony
              </h4>
              <p className="pb-2">
                Evergreen Lake House
              </p>
              <p className="pb-2">
                29612 Upper Bear Creek Road, Evergreen, CO, 80439
              </p>
              <p className="pb-2 italic">
                Semi-Formal / Cocktail Attire
              </p>
              <p>
                Our ceremony will take place outdoors overlooking Evergreen Lake
              </p>
            </div>
            < hr className='col-span-2' />

            <div>
              <h4 className="text-gray-800 text-lg">
                5:30PM – 6:30PM
              </h4>
            </div>
            <div>
              <h4 className="text-left uppercase">
                Cocktail Hour
              </h4>
              <p>
                Mingle, enjoy drinks and appetizers, and celebrate the moments before we dine!
              </p>
            </div>
            < hr className='col-span-2' />

            <div>
              <h4 className="text-gray-800 text-lg">
                6:30PM – 10:00PM
              </h4>
            </div>
            <div>
              <h4 className="text-left uppercase">
                Reception
              </h4>
              <p>
                Stay for dinner, drinks, and dancing right inside Evergreen Lake House after our ceremony!
              </p>
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
