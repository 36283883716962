import { useState } from 'react';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="w-full h-20 bg-alabaster-100 flex flex-wrap items-center justify-between selection:bg-genoa-200 fixed top-0 z-10">
      {/* Hamburger menu */}
      <button 
        className="sm:hidden p-4 focus:outline-none focus:bg-gray-700" 
        onClick={() => setIsOpen(!isOpen)}
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
        </svg>
      </button>

      {/* Full screen overlay */}
      {isOpen && (
        <div className="fixed inset-0 bg-alabaster-900 z-9 flex items-center justify-center sm:hidden">
          <div className="bg-alabaster-100 p-4 rounded text-center">
            <NavbarItem href="/" label="Home" onClick={() => setIsOpen(false)} />
            <NavbarItem href="/GalleryOfAdventures" label="Gallery Of Adventures" onClick={() => setIsOpen(false)} />
            <NavbarItem href="/Accommodations" label="Accommodations" onClick={() => setIsOpen(false)} />
            <NavbarItem href="/FAQ" label="FAQ" onClick={() => setIsOpen(false)} />
            <NavbarItem href="/Registry" label="Registry" onClick={() => setIsOpen(false)} />
            <NavbarItem href="/RSVP" label="RSVP" onClick={() => setIsOpen(false)} />
          </div>
        </div>
      )}

      {/* Normal Links for large screen */}
      <div className="hidden sm:flex w-full sm:w-auto">
        <NavbarItem href="/" label="Home" />
        <NavbarItem href="/GalleryOfAdventures" label="Gallery Of Adventures" />
        <NavbarItem href="/Accommodations" label="Accommodations" />
        <NavbarItem href="/FAQ" label="FAQ" />
        <NavbarItem href="/Registry" label="Registry" />
        <NavbarItem href="/RSVP" label="RSVP" />
      </div>
    </div>
  );
}

function NavbarItem({ href, label, onClick }) {
  return (
    <a 
      href={href} 
      className="group rounded px-6 py-2 flex-none grid grid-cols-1 content-center justify-center place-content-center text-alabaster-700 hover:group-hover:bg-alabaster-400"
      onClick={onClick}
    >
      <div className="group-hover:bg-genoa-300 group-hover:text-white text-xl font-body inline-block rounded px-2">
        {label}
      </div>
    </a>
  );
}

export default Navbar;
