import Navbar from './Navbar';
import Footer from './Footer';

function FAQ() {
    return (
        <div className='min-h-screen  bg-white grid grid-cols-1 selection:bg-genoa-200 py-20'>
            <Navbar />
            <div className="prose lg:w-3/5 px-4 lg:px-0 justify-self-center align-baseline mb-20">
                <h2>
                    FAQ
                </h2>
                <section className="mb-8">
                    <h3>
                        Frequently Asked Questions
                    </h3>
                    <p>
                        We've compiled answers to some common questions about our special day. If you have any further inquiries, feel free to reach out to us.
                    </p>

                    <hr />
                    <h4 className='text-left font-bold'>
                        Where is the wedding located? Should I rent a car?
                    </h4>
                    <p>
                    Our wedding will be held in Evergreen, CO, which is about a 35-minute drive from Denver and a 50 minute drive from the Denver International Airport.
                    Unfortunately there are no great public transportation options so we'd recommend renting a car / using Turo or carpooling with others!
                    </p>

                    <h4 className='text-left font-bold'>
                        What are the parking arrangements?
                    </h4>
                    <p>
                        Evergreen Lake House offers free public parking. Vehicles can be left overnight. For guests planning to drink, we recommend designating a driver or using Uber or Lyft for safe transportation. Please note that these services can be limited in availability in Evergreen, so we recommend scheduling your ride in advance.
                    </p>

                    <h4 className='text-left font-bold'>
                        What is the elevation in Evergreen?
                    </h4>
                    <p>
                        Evergreen sits over 7,200 feet above sea level. Please keep this in mind when planning activities, especially if they involve exercise or enjoying a few drinks!
                    </p>

                    <h4 className='text-left font-bold'>
                        What is the exact schedule for the wedding day?
                    </h4>
                    <p>
                        The ceremony will commence at 5PM on April 27th and is expected to conclude around 10PM.
                    </p>

                    <h4 className='text-left font-bold'>
                        Can I bring a date to the wedding?
                    </h4>
                    <p>
                        To adhere to our budget and venue constraints, our wedding can accommodate only those guests explicitly listed on your invitation.
                    </p>

                    <h4 className='text-left font-bold'>
                        What about Uber/Lyft availability?
                    </h4>
                    <p>
                        Ride-sharing options like Uber and Lyft are quite limited in Evergreen in the offseason. If you're planning to use these services, we recommend scheduling your rides in advance through the respective apps to ensure availability.
                    </p>

                    <h4 className='text-left font-bold'>
                        What are some things to do in the area?
                    </h4>
                    <p>
                        Evergreen offers a variety of attractions! Enjoy a tour of the Coors Brewery, experience the beauty of Red Rocks Park and Amphitheatre, or explore scenic hiking trails. AllTrails features 74 trails in the Evergreen area, perfect for hiking, biking, and trail running, complete with maps, reviews, and photos.
                    </p>

                    <h4 className='text-left font-bold'>
                        What if I have a dietary restriction or my question isn't covered?
                    </h4>
                    <p>
                        Please contact us if you have a dietary restriction or any other questions at 425-736-4762!
                    </p>

                </section>
            </div>
            <Footer />
        </div>
    );
}

export default FAQ;
