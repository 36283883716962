import Navbar from './Navbar';
import Footer from './Footer';
import csImage from './assets/ComfortSuites.jpg';
import fiImage from './assets/FairfieldInn.jpg';
import hhImage from './assets/HighlandHaven.jpg';
import awImage from './assets/AlpenWay.jpg';

function Accommodations() {
    return (
        <div className='min-h-screen  bg-white grid grid-cols-1 selection:bg-genoa-200 py-20'>
            <Navbar />
            <div className="prose lg:w-3/5 px-4 lg:px-0 justify-self-center align-baseline mb-20">
                <h2>
                    Accommodations
                </h2>
                <section className="mb-8">

                    <div className='grid items-center'>

                        <a href="https://goldenevergreenhotel.com/" className="text-center">
                            <h3>
                                Comfort Suites Golden West on Evergreen Parkway
                            </h3>
                        </a>
                    </div>
                    <img src={csImage} alt="Picture of Comfort Suites" className="rounded mb-2" />
                    <p className="text-center">
                        29300 US-40, Evergreen, CO 80439, USA
                    </p>
                    <p className="text-center">
                        (303) 526-2000
                    </p>

                    <h4>
                        Booking Details:
                    </h4>
                    <p>
                        To reserve within the hotel block at the discounted rate, please contact the hotel and reference the "Barron/Beynor Wedding”.

                        Guests that select this option will receive the discounted rate along with free WiFi, free daily breakfast, and access to the properties amenities such as indoor pool and fitness center.
                        <br />
                        <br />
                        The discount rates are available from 4/26/24 - 4/28/24 and must be reserved by March 27th.
                        <br />
                        <br />
                        This hotel is a 13 minute drive from the venue.
                    </p>
                </section>
                <hr />
                <section className="mb-8">
                    <div className='grid items-center'>
                        <a href="https://www.alpenwaychalet.com/" className="text-center">
                            <h3>
                                The Alpen Way Chalet Mountain Lodge
                            </h3>
                        </a>
                    </div>
                    <img src={awImage} alt="Picture of The Alpen Way Chalet" className="rounded mb-2" />
                    <p className="text-center">
                        4980 Highway 73, Evergreen, CO 80439, USA
                    </p>
                    <p className="text-center">
                        (303) 674-7467
                    </p>
                    <h4>
                        Booking Details:
                    </h4>
                    <p>
                        The Alpen Way Chalet Mountain Lodge offers a cozy mountain retreat with amenities such as free WiFi, private hot tubs, and easy access to the Evergreen area.
                        <br />
                        <br />
                        This lodge is a 5 minute drive from the venue.
                    </p>
                </section>
                <hr />
                <section className="mb-8">
                    <div className='grid items-center'>
                        <a href="https://highlandhaven.com/" className="text-center">
                            <h3>
                                Highland Haven
                            </h3>
                        </a>
                    </div>

                    <img src={hhImage} alt="Picture of Highland Haven" className="rounded mb-2" />
                    <p className="text-center">
                        4395 Independence Trail, Evergreen, CO 80439, USA
                    </p>
                    <p className="text-center">
                        (303) 674-3577
                    </p>
                    <h4>
                        Booking Details:
                    </h4>
                    <p>
                        Highland Haven offers a unique and luxurious experience with its beautiful creekside cabins, suites, and rooms. Guests can enjoy free WiFi, delicious breakfasts, and the serene natural surroundings.
                        <br />
                        <br />
                        This inn is a 6 minute drive from the venue.
                    </p>
                </section>
            </div>
            <Footer />
        </div>
    );
}

export default Accommodations;
