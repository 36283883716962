import Navbar from './Navbar';
import Footer from './Footer';

function Registry() {
    return (
        <div className='min-h-screen w-screen bg-white grid grid-cols-1 selection:bg-genoa-200 py-20'>
            <Navbar />
            <div className="prose lg:w-3/5 px-4 lg:px-0 justify-self-center align-baseline">
                <h2>
                    Registry
                </h2>

                <section className="mb-8 ">
                    <p>
                        The presence of each of you in Colorado for our special day is the only gift we need! However if you feel compelled to gift us something:
                    </p>
                    <div class="grid grid-cols-2 py-6 gap-4">  {/* Changed from flex to flex-col and added space between items */}

                        {/* <!-- Honeymoon Fund Tile --> */}
                        <a href="https://www.paypal.com/paypalme/jibandtrb" class="w-full h-40 rounded-xl bg-gradient-to-bl via-gray-200 from-genoa-300 hover:from-genoa-400 text-alabaster-600 font-body text-center no-underline transition duration-1000 text-2xl flex items-center justify-center">
                            Honeymoon Fund
                            <br />
                            (paypal)
                        </a>
                        <a href="https://account.venmo.com/u/TBarr" class="w-full h-40 rounded-xl bg-gradient-to-bl via-gray-200 from-genoa-300 hover:from-genoa-400 text-alabaster-600 font-body text-center no-underline transition duration-1000 text-2xl flex items-center justify-center">
                            Honeymoon Fund
                            <br />
                            (venmo)
                        </a>

                        {/* <!-- Cat Fund Tile --> */}
                        <a href="https://www.paypal.com/paypalme/jibandtrb" class="w-full h-40 rounded-xl bg-gradient-to-br via-gray-200 from-genoa-300 hover:from-genoa-400 text-alabaster-600 font-body text-center no-underline transition duration-1000 text-2xl flex items-center justify-center">
                            Spoil-The-Cats Fund
                            <br />
                            (paypal)
                        </a>
                        <a href="https://account.venmo.com/u/TBarr" class="w-full h-40 rounded-xl bg-gradient-to-br via-gray-200 from-genoa-300 hover:from-genoa-400 text-alabaster-600 font-body text-center no-underline transition duration-1000 text-2xl flex items-center justify-center">
                            Spoil-The-Cats Fund
                            <br />
                            (venmo)
                        </a>

                        {/* <!-- Lego Fund Tile --> */}
                        <a href="https://www.paypal.com/paypalme/jibandtrb" class="w-full h-40 rounded-xl bg-gradient-to-tr via-gray-200 from-genoa-300 hover:from-genoa-400 text-alabaster-600 font-body text-center no-underline transition duration-1000 text-2xl flex items-center justify-center">
                            Home Improvement Fund
                            <br />
                            (paypal)
                        </a>
                        <a href="https://account.venmo.com/u/TBarr" class="w-full h-40 rounded-xl bg-gradient-to-br via-gray-200 from-genoa-300 hover:from-genoa-400 text-alabaster-600 font-body text-center no-underline transition duration-1000 text-2xl flex items-center justify-center">
                            Home Improvement Fund
                            <br />
                            (venmo)
                        </a>
                    </div>

                </section>
            </div>
            <Footer />
        </div>

    );
}

export default Registry;
