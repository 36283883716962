import React, { useState, useEffect } from 'react';
import { DynamoDB } from 'aws-sdk';
import { awsConfig } from './creds';
import Navbar from './Navbar';
import Footer from './Footer';

const AttendeesList = () => {
  const [attendees, setAttendees] = useState([]);
  const [error, setError] = useState(null);

  const dynamoDbClient = new DynamoDB.DocumentClient({
    region: 'us-west-2',
    credentials: {
      accessKeyId: awsConfig.accessKey,
      secretAccessKey: awsConfig.accessSecret
    }
  });

  useEffect(() => {
    const fetchAttendees = async () => {
      const params = {
        TableName: 'weddingRSVP'
      };

      try {
        const result = await dynamoDbClient.scan(params).promise();
        const formattedAttendees = result.Items.map(item => {
          return item.name.map(personName => {
            const firstName = personName.split(' ')[0].toLowerCase();
            return {
              name: personName,
              attending: item[`attending:${firstName}`],
              foodSelection: item[`foodSelection:${firstName}`],
              attendingWD: item[`attendingWD:${firstName}`],
              notes: item[`notes:${firstName}`],
              location: item[`notes:location`]
            };
          });
        }).flat(); // Flatten the array of arrays into a single array of attendee objects
        setAttendees(formattedAttendees);
      } catch (err) {
        setError(`An error occurred: ${err}`);
      }
    };

    fetchAttendees();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 px-4 lg:px-0">
      <Navbar />
      <div className="mt-8 w-full max-w-5xl">
        <h1 className="text-2xl font-semibold text-center mb-4">Attendees List</h1>
        {error && <p className="text-red-500">{error}</p>}
        <div className="overflow-x-auto mb-20">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50 sticky top-0 z-10 shadow">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border border-gray-300">
                  Name
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border border-gray-300">
                  RSVP Status (Wedding)
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border border-gray-300">
                  Food Selection
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border border-gray-300">
                  RSVP Status (Welcome Drinks)
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border border-gray-300">
                  Notes
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border border-gray-300">
                  Location
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {attendees.map((attendee, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border border-gray-300">
                    {attendee.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border border-gray-300">
                    {attendee.attending}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border border-gray-300">
                    {attendee.foodSelection}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border border-gray-300">
                    {attendee.attendingWD}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border border-gray-300">
                    {attendee.notes}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border border-gray-300">
                    {attendee.location}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AttendeesList;
