import Footer from './Footer';
import React, { useState } from 'react';
import { DynamoDB } from 'aws-sdk';
import { awsConfig } from './creds';
import Navbar from './Navbar';

const RSVP = () => {
  const [inputName, setInputName] = useState('');
  const [error, setError] = useState(null);

  const dynamoDbClient = new DynamoDB.DocumentClient({
    region: 'us-west-2',
    credentials: {
      accessKeyId: awsConfig.accessKey,
      secretAccessKey: awsConfig.accessSecret
    }
  });

  const searchName = async () => {
    const sanitizedInputName = inputName.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "").toLowerCase();

    const params = {
      TableName: 'weddingRSVP',
      FilterExpression: 'contains (#nameAttribute, :nameValue)',
      ExpressionAttributeNames: {
        '#nameAttribute': 'name'
      },
      ExpressionAttributeValues: {
        ':nameValue': sanitizedInputName
      }
    };

    try {
      const result = await dynamoDbClient.scan(params).promise();
      console.log(result);
      if (result.Items.length > 0) {
        const jibandtrbValue = result.Items[0].jibandtrb;
        window.location.href = `/SetSelections/${jibandtrbValue}`;
      } else {
        setError('Name not found. Please try again.');
      }
    } catch (err) {
      setError('An error occurred. Please try again.' + err);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen px-4 lg:px-0 bg-gray-100">
      <Navbar />
      <div 
        className="px-6 py-4 border rounded-md shadow-md bg-alabaster-800 w-full md:w-3/4 lg:w-1/4 text-white mb-4"
      >
        <h2 className="text-sm font-medium text-gray-50 mb-4">RSVP</h2>
        <div className="mb-4">
          <label 
            htmlFor="inputName" 
            className="block text-sm font-medium text-gray-50"
          >
            Your Name (ex: Tyler Barron)
          </label>
          <input 
            id="inputName"
            type="text"
            value={inputName}
            onChange={e => setInputName(e.target.value)}
            onKeyPress={e => e.key === 'Enter' && searchName()}
            className="mt-1 p-2 w-full border rounded-md text-alabaster-800" 
          />
          <button 
            onClick={searchName} 
            className="w-full bg-genoa-500 text-white p-2 rounded-md mt-4"
          >
            Submit
          </button>
          {error && <div className="text-red-400 mt-2">{error}</div>}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RSVP;
