import Navbar from './Navbar';
import Footer from './Footer';
import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item } from 'react-photoswipe-gallery'

const images = [
    { id: 0, src: './Gallery/cafeP.jpg', title: 'The site of our first date!', width: 640, height: 480 },
    { id: 1, src: './Gallery/bikers.jpg', title: 'Bikers in Europe!', width: 2316, height: 3088 },
    { id: 2, src: './Gallery/boatGang.jpg', title: 'Boaters in Europe!', width: 3024, height: 4032 },
    { id: 3, src: './Gallery/engagement.jpg', title: 'She said yes!!', width: 3024, height: 4032 },
    { id: 4, src: './Gallery/f1Fans.jpg', title: 'F1 fans!', width: 3000, height: 4000 },
    { id: 5, src: './Gallery/fancyHotel.jpg', title: 'a fancy hotel experience!', width: 3024, height: 4032 },
    { id: 6, src: './Gallery/halloween.png', title: 'Halloween 2023', width: 1284, height: 1704 },
    { id: 7, src: './Gallery/hike.png', title: 'A couple of Colorado hikers', width: 1284, height: 964 },
    { id: 8, src: './Gallery/hike2.jpg', title: 'A couple of Colorado hikers pt 2', width: 1024, height: 768 },
    { id: 9, src: './Gallery/homeDate.png', title: 'A covid era date!', width: 1284, height: 1709 },
    { id: 10, src: './Gallery/marathonMan.jpg', title: 'The best runner support', width: 1024, height: 768 },
    { id: 11, src: './Gallery/medSchoolProm.jpg', title: 'Medical School Prom', width: 3024, height: 4032 },
    { id: 12, src: './Gallery/miamiBeach.jpg', title: 'Miami Beach Enjoyers!', width: 768, height: 1024 },
    { id: 13, src: './Gallery/pensive.jpg', title: 'Our faces coming up with each of these captions', width: 1024, height: 768 },
    { id: 14, src: './Gallery/rodeo.png', title: '"lock him up" - Jessie', width: 1284, height: 959 },
    { id: 15, src: './Gallery/sammyBeach.jpg', title: "Ty's first time on the Cape!", width: 768, height: 1024 },
    { id: 16, src: './Gallery/savingJessie.jpg', title: 'A harrowing adventure', width: 1024, height: 768 },
    { id: 17, src: './Gallery/sportsFans.jpg', title: 'True sports fans in action', width: 1600, height: 1200 },
    { id: 18, src: './Gallery/vegas.jpg', title: 'Experience Las Vegas (although it looks like NYC)', width: 768, height: 1024 },
    { id: 19, src: './Gallery/vineyardGang2.jpg', title: 'Touring how wine is made', width: 768, height: 1024 },
    { id: 20, src: './Gallery/winterSolstace.jpg', title: 'Winter Solstace Party!', width: 768, height: 1024 },
];

function GalleryOfAdventures() {
    return (
        <div className='min-h-screen w-screen bg-white grid grid-cols-1 selection:bg-genoa-200 py-20'>
            <Navbar />
            <div className="prose lg:w-3/5 px-4 lg:px-0 justify-self-center align-baseline">
                <h2>
                    Gallery of Adventures
                </h2>
                <section className="mb-8">
                    <p>
                        Here are a few photos reminding us of our favorite moments together over the years
                    </p>
                    <Gallery withCaption>
                        {images.map((image) => (
                            <Item
                                key={image.id}
                                original={image.src}
                                thumbnail={image.src}
                                width={image.width}
                                height={image.height}
                                caption={image.title}
                            >
                                {({ ref, open }) => (
                                    <img ref={ref} onClick={open} src={image.src} alt={image.title} />
                                )}
                            </Item>
                        ))}
                    </Gallery>
                </section>
            </div>
            <Footer />
        </div>
    );
}

export default GalleryOfAdventures;
