import Footer from './Footer';
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";

const Login = ({ checkPassword }) => {
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleSubmit = (e) => {
      e.preventDefault();
      if (checkPassword(password)) {
        navigate("/");
      };
    };
  
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <form 
          onSubmit={handleSubmit} 
          className="p-8 border rounded-md shadow-md bg-alabaster-800 w-full md:w-3/4 lg:w-1/4 text-white mb-4"
        >
          <div className="mb-4">
            <label 
              htmlFor="password" 
              className="block text-sm font-medium text-gray-50"
            >
              Password
            </label>
            <input 
              type="password" 
              id="password"
              autoComplete="new-password"
              className="mt-1 p-2 w-full border rounded-md text-alabaster-800" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button 
            type="submit" 
            className="w-full bg-genoa-500 text-white p-2 rounded-md"
          >
            Submit
          </button>
        </form>
        <Footer />
      </div>
    );
  };
  
  export default Login;
  