function Footer() {
    return (
      <div className="w-full h-20 bg-alabaster-100 flex place-self-end text-body align-center place-contents-center justify-center selection:bg-genoa-200 fixed bottom-0">
        <div className='font-body text-alabaster-700 my-auto place-self-start pl-4'>
            <div className='text-center'>
                Made with love from Denver, CO
            </div>
            <div className='text-center'>
                V1.6.0
            </div>        
        </div>
      </div>
    );
  }

  export default Footer;
