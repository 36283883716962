import React, { useState } from 'react';  // add useState
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from './App';
import ErrorPage from './error-page';
import Accommodations from './Accomodations';
import GalleryOfAdventures from './GalleryOfAdventures';
import Registry from './Registry';
import RSVP from './RSVP';
import Login from './Login';
import SetSelections from './SetSelections';
import FAQ from './FAQ';
import AttendeesList from './AttendeesList';

const root = ReactDOM.createRoot(document.getElementById('root'));

const RootApp = () => {
  const isAuth = true; //localStorage.getItem('isAuth') === 'true';

  const checkPassword = (password) => {
    return true;
    // if (password === "jibandtrb") {
    //   setIsAuth(true);
    //   localStorage.setItem('isAuth', true);
    //   setTimeout(() => localStorage.removeItem('isAuth'), 48 * 60 * 60 * 1000);
    //   return true
    // }
  };

  const secureRoutes = isAuth ? [
    {
      path: "/",
      element: <App />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/Accommodations",
      element: <Accommodations />,
    },
    {
      path: "/GalleryOfAdventures",
      element: <GalleryOfAdventures />,
    },
    {
      path: "/FAQ",
      element: <FAQ />,
    },
    {
      path: "/Registry",
      element: <Registry />,
    },
    {
      path: "/RSVP",
      element: <RSVP />,
    },
    {
      path: "/AttendeesList",
      element: <AttendeesList />,
    },
    {
      path: "/SetSelections/:userID",
      element: <SetSelections />,
    }
    ] : [];

  const alwaysAccessibleRoutes = [
    {
      path: "/login",
      element: <Login checkPassword={checkPassword} />,
    },
    {
      path: '*',
      element: <Login checkPassword={checkPassword} />,
    },
  ];

  const router = createBrowserRouter([...secureRoutes, ...alwaysAccessibleRoutes]);

  return (
    <RouterProvider router={router} />
  );
};

root.render(
  <React.StrictMode>
    <RootApp />
  </React.StrictMode>
);

reportWebVitals();

