import React, { useState, useEffect } from 'react';
import { DynamoDB } from 'aws-sdk';
import { awsConfig } from './creds';
import { useParams } from 'react-router-dom';
import Footer from './Footer';
import Navbar from './Navbar';

const SetSelections = () => {
  const { userID } = useParams();
  const [names, setNames] = useState([]);
  const [selections, setSelections] = useState({});
  const [error, setError] = useState(null);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [localSelections, setLocalSelections] = useState({});

  const dynamoDbClient = new DynamoDB.DocumentClient({
    region: 'us-west-2',
    credentials: {
      accessKeyId: awsConfig.accessKey,
      secretAccessKey: awsConfig.accessSecret
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        TableName: 'weddingRSVP',
        Key: { 'jibandtrb': userID }
      };
      try {
        const result = await dynamoDbClient.get(params).promise();
        if (result.Item) {
          const capitalizedNames = result.Item.name.slice(0, 2).map(name => name.charAt(0).toUpperCase() + name.slice(1));
          setNames(capitalizedNames);
          setSelections(result.Item); // Store the existing selections
        } else {
          setError('Record not found.');
        }
      } catch (err) {
        setError('An error occurred. ' + err);
      }
    };
    fetchData();
  }, [userID]);

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        TableName: 'weddingRSVP',
        Key: { 'jibandtrb': userID }
      };

      try {
        const result = await dynamoDbClient.get(params).promise();

        if (result.Item) {
          const capitalizedNames = result.Item.name.slice(0, 2).map(name =>
            name.charAt(0).toUpperCase() + name.slice(1));
          setNames(capitalizedNames);

          // Initialize local selections from the fetched data
          const localData = {};
          Object.keys(result.Item).forEach((key) => {
            if (key.includes(':')) {
              const [field, firstName] = key.split(':');
              localData[firstName] = {
                ...localData[firstName],
                [field]: result.Item[key]
              };
            }
          });
          setLocalSelections(localData);

        } else {
          setError('Record not found.');
        }
      } catch (err) {
        setError('An error occurred. ' + err);
      }
    };

    fetchData();
  }, [userID]);


  const updateLocalSelection = (name, field, value) => {
    const firstName = name.split(' ')[0].toLowerCase();
    setLocalSelections({
      ...localSelections,
      [firstName]: {
        ...localSelections[firstName],
        [field]: value,
      },
    });
  };

  const updateSelection = async (name, field, value) => {
    const firstName = name.split(' ')[0].toLowerCase();
    const fieldName = `${field}:${firstName}`;
    const params = {
      TableName: 'weddingRSVP',
      Key: { 'jibandtrb': userID },
      UpdateExpression: `set #fieldName = :value, dateOfEntry = list_append(if_not_exists(dateOfEntry, :emptyList), :newDate)`,
      ExpressionAttributeNames: { '#fieldName': fieldName },
      ExpressionAttributeValues: {
        ':value': value,
        ':newDate': [new Date().toISOString()],
        ':emptyList': []
      }
    };
    try {
      await dynamoDbClient.update(params).promise();
      return true
    } catch (err) {
      setError('An error occurred. ' + err);
      return false
    }
  };

  const handleSave = async () => {

    let successfulSave = true

    // Loop through local selections and save them
    for (const firstName in localSelections) {
      for (const [field, value] of Object.entries(localSelections[firstName])) {
        const saveResult = await updateSelection(firstName, field, value);
        if (!saveResult) successfulSave = false;
      }
    }
    if (successfulSave) setSaveSuccess(true);
    setTimeout(() => setSaveSuccess(false), 3500);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 px-4 lg:px-0 py-28">
      <Navbar />
      {names.map((name, index) => {
        const firstName = name.split(' ')[0].toLowerCase();
        return (
          <div key={index} className="px-6 py-4 border rounded-md shadow-md bg-alabaster-800 w-full md:w-3/4 lg:w-1/4 text-white mb-4">
            <h2 className="text-lg font-medium text-gray-50 mb-4 capitalize">{name}</h2>
            <div className={`${name === "Guest" ? "block" : "hidden"} mb-2`}>
              <label className="block text-sm font-medium text-gray-50">Guest Name</label>
              <textarea
                rows="1"
                className="mt-1 p-2 w-full border rounded-md text-gray-900"
                placeholder=""
                value={localSelections[firstName]?.guest !== undefined ? localSelections[firstName]?.guest : selections?.[`guest:${firstName}`] || ''}
                onChange={(e) => updateLocalSelection(name, 'guest', e.target.value)}
              />
            </div>
            <div className="mb-2">
              <label className="block text-sm font-medium text-gray-50">Attending Wedding on Saturday at 5PM</label>
              <select
                className="mt-1 p-2 w-full border rounded-md text-gray-900"
                value={localSelections[firstName]?.attending || ''}
                onChange={(e) => updateLocalSelection(name, 'attending', e.target.value)}
              >
                <option value="">--Select--</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div className="mb-2">
              <label className="block text-sm font-medium text-gray-50">Food Selection</label>
              <select
                className="mt-1 p-2 w-full border rounded-md text-gray-900"
                value={localSelections[firstName]?.foodSelection || ''}
                onChange={(e) => updateLocalSelection(name, 'foodSelection', e.target.value)}
              >
                <option value="">--Select--</option>
                <option value="chicken">Pan Roasted Chicken</option>
                <option value="gnocchi">Pesto Gnocchi</option>
                <option value="risotto">Fall Risotto</option>
              </select>
            </div>
            <div className="mb-2">
              <label className="block text-sm font-medium text-gray-50">Attending Welcome Drinks on Friday at 7PM</label>
              <select
                className="mt-1 p-2 w-full border rounded-md text-gray-900"
                value={localSelections[firstName]?.attendingWD || ''}
                onChange={(e) => updateLocalSelection(name, 'attendingWD', e.target.value)}
              >
                <option value="">--Select--</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div className="mb-2">
              <label className="block text-sm font-medium text-gray-50">Notes, Allergies, Song Suggestions, etc.!</label>
              <textarea
                rows="2"
                className="mt-1 p-2 w-full border rounded-md text-gray-900 md:h-20 lg:h-24"
                placeholder="Any notes or song suggestions"
                value={localSelections[firstName]?.notes !== undefined ? localSelections[firstName]?.notes : selections?.[`notes:${firstName}`] || ''}
                onChange={(e) => updateLocalSelection(name, 'notes', e.target.value)}
              />
            </div>
          </div>
        );
      })}
      <div className="px-6 py-4 border rounded-md shadow-md bg-alabaster-800 w-full md:w-3/4 lg:w-1/4 text-white mb-4">
        <h2 className="text-lg font-medium text-gray-50 mb-4">Accommodation Details</h2>
        <div className="mb-2">
          <label className="block text-sm font-medium text-gray-50">Do you know where you are staying? If so, please input below</label>
          <textarea
                rows="2"
                className="mt-1 p-2 w-full border rounded-md text-gray-900 md:h-20 lg:h-24"
                placeholder=""
                value={localSelections["location"]?.notes !== undefined ? localSelections["location"]?.notes : selections?.[`notes:${"location"}`] || ''}
                onChange={(e) => updateLocalSelection("location", 'notes', e.target.value)}
              />
        </div>
      </div>
      <button
        onClick={handleSave}
        className={`w-full md:w-3/4 lg:w-1/4 p-2 rounded-md mt-1 ${saveSuccess ? 'bg-green-500' : 'bg-blue-500'} text-white`}>
        {saveSuccess ? 'Saved!' : 'Save'}
      </button>
      {error && <div className="text-red-400 mt-2">{error}</div>}
      <Footer />
    </div>
  );
};

export default SetSelections;
